<template>
  <div :class="background" class="review-card">
    <BaseText size="subheadline-small mb-s2" color="text-text-active-2">
      {{ $t(title) }}
    </BaseText>
    <div class="flex gap-s8 my-s8">
      <template v-for="starIndex in score">
        <IconStar :key="starIndex" class="w-s24 h-s24" />
      </template>
    </div>
    <BaseText size="body-text-x-space mt-s2">
      {{ $t(description) }}
    </BaseText>
    <BaseText size="body-text-x-large mt-auto" position="text-right">
      {{ $t(author) }}
    </BaseText>
  </div>
</template>
<script>
import { BaseText } from '@/components/misc';
import { IconStar } from '@/assets/icons';

export default {
  name: 'ReviewCard',
  components: {
    BaseText,
    IconStar,
  },
  props: {
    background: {
      type: String,
      default: 'bg-background-page',
    },
    title: {
      type: String,
      required: true,
    },
    score: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    author: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.review-card {
  @apply flex flex-col p-s16 rounded-16;
  min-height: 250px;
}
</style>
