export default [
  {
    title: 'review_section.reviews.review_1.title',
    score: 5,
    description: 'review_section.reviews.review_1.description',
    author: 'review_section.reviews.review_1.author',
  },
  {
    title: 'review_section.reviews.review_2.title',
    score: 5,
    description: 'review_section.reviews.review_2.description',
    author: 'review_section.reviews.review_2.author',
  },
  {
    title: 'review_section.reviews.review_3.title',
    score: 5,
    description: 'review_section.reviews.review_3.description',
    author: 'review_section.reviews.review_3.author',
  },
  {
    title: 'review_section.reviews.review_4.title',
    score: 5,
    description: 'review_section.reviews.review_4.description',
    author: 'review_section.reviews.review_4.author',
  },
  {
    title: 'review_section.reviews.review_5.title',
    score: 5,
    description: 'review_section.reviews.review_5.description',
    author: 'review_section.reviews.review_5.author',
  },
  {
    title: 'review_section.reviews.review_6.title',
    score: 5,
    description: 'review_section.reviews.review_6.description',
    author: 'review_section.reviews.review_6.author',
  },
];
