<template>
    <CentralizedSection :maxWidth="maxWidth">
        <div class="partnership-container">

            <div class="flex flex-col gap-s16 w-full ">
                <BaseText position="text-center" size="headline-small start-lg:headline-large">
                    {{ $t('about_us.partnerships_section.title') }}
                </BaseText>
                <div class="flex flex-col gap-s4 w-full ">
                  <BaseText position="text-center" size="body-text-x-space">
                    {{ $t('about_us.partnerships_section.subtitle') }}
                  </BaseText>
                  <BaseText position="text-center" size="body-text-x-space">
                    {{ $t('about_us.partnerships_section.subtitle_cont') }}
                  </BaseText>
                </div>
            </div>

            <div class="flex justify-center">
                <ButtonV2 @onClick="goToExternalPageGlobal('https://dibbs.io/partnerships')"
                    :label="$t('about_us.partnerships_section.button')" size="medium" />
            </div>

        </div>
    </CentralizedSection>
</template>

<script>
import {
  CentralizedSection,
  BaseText,
  ButtonV2,
} from '@/components/misc';

export default {
  name: 'PartnershipSection',
  components: {
    BaseText,
    ButtonV2,
    CentralizedSection,
  },
  props: {
    maxWidth: {
      type: String,
      default: '1032px',
    },
  },
};
</script>

<style scoped>
  .partnership-container {
    @apply grid gap-s64 my-s64 self-center;
  }

  @screen start-lg {
    .partnership-container {
      @apply gap-s64;
    }
  }
</style>
