<template>
  <LayoutWithoutPadding>
    <main class="flex flex-col w-full">
      <component
        v-for="(section, index) in sections"
        :key="`${section.component}-${index}`"
        :is="section.component"
        v-bind="section.props"
        v-bind:background="`bg-background-${index % 2 !== 0 ? 'page' : 'primary'}`"
        v-bind:maxWidth="'1032px'"
        v-on="section.events"
        :class="`bg-background-${index % 2 === 0 ? 'page' : 'primary'}`"
      />
    </main>
  </LayoutWithoutPadding>
</template>

<script>
import { LayoutWithoutPadding } from '@/components/misc';

import { AboutDibbsSection, CareersSection, PartnershipSection, InvestorSection,  PressSection, ReviewSection, reviews, TopSection } from '@/modules/about';

export default {
  name: 'AboutUsPage',
  components: {
    AboutDibbsSection,
    CareersSection,
    PartnershipSection,
    InvestorSection,
    LayoutWithoutPadding,
    PressSection,
    ReviewSection,
    TopSection,
  },

  computed: {
    sections() {
      return [
        {
          component: 'TopSection',
        },
        {
          component: 'AboutDibbsSection',
        },
        {
          component: 'ReviewSection',
          props: {
            reviews,
          },
        },
        {
          component: 'InvestorSection',
        },
        {
          component: 'CareersSection',
        },
        {
          component: 'PartnershipSection',
        },
        {
          component: 'PressSection',
        }
      ];
    },
  },
};
</script>
