<template>
  <CentralizedSection :maxWidth="maxWidth">
    <div class="flex flex-col justify-center items-center gap-s40 start-lg:gap-s64">
      <BaseText
        size="headline-small start-lg:headline-large"
        position="text-center">{{ $t('about_us.about_dibbs.title') }}</BaseText>

      <div class="whitespace-pre-wrap">
        <BaseText
          :size="descriptionClass">{{$t('about_us.about_dibbs.description')}}</BaseText>
      </div>
    </div>
  </CentralizedSection>
</template>

<script>
import { BaseText, CentralizedSection } from '@/components/misc';

export default {
  name: 'AboutDibbs',
  components: {
    BaseText,
    CentralizedSection,
  },
  computed: {
    descriptionClass() {
      return this.isMobileDevice ? 'body-text-x-space' : 'body-text-x-space-desktop';
    },
  },
  props: {
    maxWidth: {
      type: String,
      default: '1032px',
    },
  },
};
</script>

<style scoped>
.body-text-x-space-desktop {
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
}
</style>
