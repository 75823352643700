<template>
  <CentralizedSection :maxWidth="maxWidth">
    <div class="flex flex-col items-center gap-s64 w-full">
      <div class="flex justify-center">
        <div class="press-header">
          <BaseText tag="h2" size="text-center headline-small start-xl:headline-large">
            {{$t('about_us.news_section.title')}}
          </BaseText>
          <BaseText size="body-text-x-space">
            {{$t('about_us.news_section.description')}}
            <a class="text-text-active-2" href="mailto:press@dibbs.io">press@dibbs.io</a>.
          </BaseText>
        </div>
      </div>

      <div class="flex flex-col gap-s24">
        <PressCard
          :key="posts.id"
          v-for="posts of pressList"
          v-bind="posts"
          v-bind:background="background"
        />
      </div>

      <ButtonV2 :label="$t('about_us.news_section.button_read_more')"
      
      testId="btn-read-more"
      @onClick="goToExternalPageGlobal('https://dibbs.io/blog/')" size="medium" />
    </div>
  </CentralizedSection>
</template>

<script>
import { DateTime } from 'luxon';
import ButtonV2 from '@/stories/misc/ButtonV2.vue';
import { BaseText, CentralizedSection } from '@/components/misc';

import PressCard from '@/modules/about/PressCard/index.vue';

export default {
  name: 'PressSection',
  components: {
    BaseText,
    ButtonV2,
    CentralizedSection,
    PressCard,
  },
  props: {
    background: {
      type: String,
      default: 'bg-background-page',
    },
    maxWidth: {
      type: String,
      default: '1032px',
    },
  },
  computed: {
    isDarkMode() {
      return this.currentTheme === 'dark';
    },
    pressList(){
      return [{
        id: 0,
        date: DateTime.local(2021, 3, 23).toMillis(),
        image: 'https://img.dibbscdn.com/forbes.png',
        logo: {
          height: 20,
          width: 76,
          src: !this.isDarkMode ? 'https://img.dibbscdn.com/forbes_logo.png' : 'https://img.dibbscdn.com/forbes_logo_dark.png',
        },
        link: 'https://www.forbes.com/sites/brettknight/2021/03/23/dibbs-sports-cards-nft-seed-funding/?sh=29b388b34c78',
        description: 'Dibbs, an app that allows users to trade fractional interests in sports cards in real time, will announce today that it has secured $2.8 million in seed funding, led by Courtside Ventures. Other investors in the round include Founder Collective, JDS Sports and Sports Card Investor, as well as former NBA player Channing Frye, WHOOP founder',
        title: 'Sports Card Marketplace Dibbs Raises $2.8 Million As NFT Mania Hits Collectibles Industry',
      },
      {
        id: 1,
        date: DateTime.local(2021, 12, 2).toMillis(),
        image: 'https://img.dibbscdn.com/bloomberg.png',
        logo: {
          height: 20,
          width: 107,
          src: !this.isDarkMode ? 'https://img.dibbscdn.com/bloomberg_logo.png' : 'https://img.dibbscdn.com/bloomberg_logo_dark.png',
        },
        link: 'https://www.bloomberg.com/news/articles/2021-12-02/amazon-invests-in-trading-card-platform-as-collectibles-boom',
        description: 'Amazon.com Inc. is investing in sports-card marketplace Dibbs, entering a booming collectibles industry as interest rises in alternative assets. The fresh funding comes as Dibbs starts a new service that allows users to buy and sell fractions of trading cards. Dibbs previously raised about $16 million from venture firms Foundry Group',
        title: 'Amazon Invests in Trading-Card Platform Dibbs as Collectibles Boom',
      },
      {
        id: 2,
        date: DateTime.local(2021, 7, 27).toMillis(),
        image: 'https://img.dibbscdn.com/wire.png',
        logo: {
          height: 20,
          width: 109,
          src: !this.isDarkMode ? 'https://img.dibbscdn.com/wire_logo.png' : 'https://img.dibbscdn.com/wire_logo_dark.png',
        },
        link: 'https://www.businesswire.com/news/home/20211202005877/en/Dibbs-Launches-%E2%80%98Sell-With-Dibbs%E2%80%99-Enabling-Fractional-Sales-of-Users%E2%80%99-Own-Collectibles',
        description: 'Dibbs, the only real-time fractional sports card marketplace, today announced its $13 million Series A financing round led by Foundry Group. Tusk Venture Partners, Courtside Ventures, and Founder Collective also participated in the round, as did a syndicate of superstar athletes, including Chris Paul, Channing Frye, DeAndre Hopkins',
        title: 'Dibbs Announces $13 Million Series A to Scale Real-Time Fractional Sports Card Marketplace',
      }];

    }
  }
};
</script>

<style scoped>
.press-header {
  @apply gap-s16 flex flex-col items-center text-justify;
}
</style>
