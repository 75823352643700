<template>
  <CentralizedSection :maxWidth="maxWidth">
    <div class="grid gap-s40 start-lg:gap-s64">

      <div class="flex flex-col gap-s16 w-full">
        <BaseText
          position="text-center"
          size="headline-small start-lg:headline-large">{{$t('about_us.investors_section.title')}}</BaseText>
        <BaseText
          position="text-center"
          size="body-text-x-space">{{$t('about_us.investors_section.description')}}</BaseText>
      </div>

      <div class="investor-container-subgrid gap-s40">
        <div class="investor-companies-grid gap-x-s20 gap-y-s40">
          <button
            :key="item.title"
            v-for="item in supportingCompanies"
            class="investor-button flex flex-col items-center gap-s16"
            @click="() => goToExternalPageGlobal(item.link)">
            <ImageResize 
              class="bg-white"
              type="about_us_investors"
              :src="item.image"
              :alt="$t('about_us.investors_section.image_alt', { title: item.title })" />
            <BaseText
              position="text-center"
              size="body-text-x-large">{{ item.title }}</BaseText>
          </button>
        </div>

        <ul class="flex flex-col gap-s16 list-disc pl-s24">
          <li :key="item.name" v-for="item in supportingPeople">
            <BaseText
              color="text-text-active-2"
              size="subheadline-medium">{{item.name}}, <span class="text-text-body">{{item.title}}</span></BaseText>
          </li>
        </ul>
      </div>

    </div>
  </CentralizedSection>
</template>

<script>
import { BaseText, CentralizedSection, ImageResize } from '@/components/misc';

export default {
  name: 'InvestorSection',
  components: {
    BaseText,
    CentralizedSection,
    ImageResize
  },
  props: {
    maxWidth: {
      type: String,
      default: '1032px',
    },
  },
  computed: {
    supportingCompanies(){
      return [
        {
          image: 'https://img.dibbscdn.com/foundry.png',
          link: 'https://www.foundrygroup.com/',
          title: 'Foundry Group'
        },
        {
          image: 'https://img.dibbscdn.com/tusk.png',
          link: 'https://tusk.vc/',
          title: 'Tusk Venture Partners'
        },
        {
          image: 'https://img.dibbscdn.com/jds.png',
          link: 'https://www.jdssports.co/',
          title: 'JDS Sports'
        },
        {
          image: 'https://img.dibbscdn.com/courtside.png',
          link: 'http://courtsidevc.com/',
          title: 'Courtside Ventures'
        },
        {
          image: 'https://img.dibbscdn.com/founder.png',
          link: 'https://www.foundercollective.com/',
          title: 'Founder Collective'
        },
        {
          image: 'https://img.dibbscdn.com/amazon.png',
          link: 'https://www.amazon.com/',
          title: 'Amazon'
        },
      ];
    },
    supportingPeople(){
      return [
        {
          name: 'Channing Frye',
          title: 'NBA Champion'
        },
        {
          name: 'Chris Paul',
          title: 'NBA Superstar'
        },
        {
          name: 'DeAndre Hopkins',
          title: 'NFL Superstar'
        },
        {
          name: 'Kevin Love',
          title: 'NBA Champion'
        },
        {
          name: 'Kris Bryant',
          title: 'MLB Champion'
        },
        {
          name: 'Skylar Diggins-Smith',
          title: 'WNBA Superstar'
        },
        {
          name: 'Roham Gharegozlou',
          title: 'CEO @ Dapper Labs'
        },
        {
          name: 'Will Ahmed',
          title: 'Founder @ WHOOP'
        },
        {
          name: 'Nat Turner',
          title: 'Cofounder @ Flatiron Health'
        },
        {
          name: 'Darren Herman',
          title: 'Partner @ Bain Capital'
        }
      ];
    }
  }
};
</script>

<style scoped>
  .investor-container-subgrid,
  .investor-companies-grid {
    @apply grid;
  }

  .investor-button {
    outline: 0;
  }

  .investor-companies-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  @screen start-md {
    .investor-companies-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @screen start-lg {
    .investor-container-subgrid {
      grid-template-columns: auto 412px;
    }
  }
</style>
