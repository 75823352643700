<template>
  <CentralizedSection :maxWidth="maxWidth">
    <div class="career-container">

      <div class="flex flex-col gap-s16 w-full">
        <BaseText position="text-center" size="headline-small start-lg:headline-large">
          {{ $t('about_us.careers_section.title') }}
        </BaseText>
        <BaseText position="text-center" size="body-text-x-space">
          {{ $t('about_us.careers_section.subtitle') }}
        </BaseText>
      </div>

      <div class="career-subgrid gap-s40">
        <ImageResize
          class="image-container"
          type="about_us_careers"
          :src="image"
          :alt="$t('about_us.careers_section.image_alt')"
        />

        <div class="flex flex-col gap-s32">
          <BaseText size="body-text-x-space">
            {{ $t('about_us.careers_section.description') }}
          </BaseText>

          <div class="">
            <BaseText size="subheadline-small">
              {{ $t('about_us.careers_section.where_we_work.title') }}
            </BaseText>

            <ul class="cities-grid">
              <li :key="`city-${index}`" v-for="(item, index) in 14" :class="`city-${index}`">
                <BaseText size="body-text-x-space">
                  {{ $t(`about_us.careers_section.where_we_work.city_${index + 1}`) }}
                </BaseText>
              </li>
            </ul>
          </div>

          <div class="">
            <BaseText size="subheadline-small">
              {{ $t('about_us.careers_section.benefits.title') }}
            </BaseText>

            <ul class="benefits-grid">
              <li :key="`benefit-${index}`" v-for="(item, index) in 6" :class="`benefit-${index}`">
                <BaseText size="body-text-x-space">
                  {{ $t(`about_us.careers_section.benefits.benefit_${index + 1}`) }}
                </BaseText>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="flex justify-center">
        <ButtonV2
          @onClick="goToExternalPageGlobal('https://dibbs.applytojob.com/apply')"
          testId="btn-about-us"
          :label="$t('about_us.careers_section.button')"
          size="medium"
        />
      </div>

    </div>
  </CentralizedSection>
</template>

<script>
import { BaseText, ButtonV2, CentralizedSection, ImageResize } from '@/components/misc';

export default {
  name: 'CareersSection',
  components: {
    BaseText,
    ButtonV2,
    CentralizedSection,
    ImageResize,
  },
  computed: {
    image() {
      return 'https://img.dibbscdn.com/team.png';
    },
  },
  props: {
    maxWidth: {
      type: String,
      default: '1032px',
    },
  },
};
</script>

<style scoped>
  .career-container {
    @apply grid gap-s40 self-center;
  }

  .career-subgrid {
    @apply grid;
  }

  .image-container {
    @apply border-text-positive-inverted rounded-24;
    border-width: 4px;
    justify-self: center;
  }

  .cities-grid {
    @apply grid list-disc mt-s8 pl-s24;
  }

  .benefits-grid {
    @apply grid list-disc mt-s8 pl-s24;
    gap: 0px 32px;
  }

  @screen start-md {
    .cities-grid {
      @apply grid-rows-5 grid-flow-col;
      grid-template-columns: 240px 240px 160px;
    }

    .benefits-grid {
      @apply grid-rows-3 grid-flow-col;
      grid-template-rows: repeat(3, minmax(24px, auto));
      grid-template-columns: 210px 300px;
    }
   
  }

  @screen start-lg {
    .career-container {
      @apply gap-s64;
    }

    .career-subgrid {
      grid-template-columns: auto 1fr;
    }
  }
</style>
