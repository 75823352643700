<template>
  <IntroVideoSection>
    <BaseText tag="h1" size="headline-large">
      {{ $t('about_us.top_section.title') }}
    </BaseText>
    <BaseText tag="h2" size="body-text-x-space mt-s8 mb-s40">
      {{ $t('about_us.top_section.subtitle') }}
    </BaseText>
  </IntroVideoSection>
</template>

<script>
import { BaseText, IntroVideoSection } from '@/components/misc';

export default {
  name: 'MobileVersion',
  components: {
    BaseText,
    IntroVideoSection,
  }
};
</script>

<style scoped>
</style>
