<template>
  <CentralizedSection class="review-section" :maxWidth="maxWidth">
    <template slot="header">
      <div class="flex justify-center mb-s32 start-lg:mb-s64">
        <div class="review-header">
          <BaseText tag="h2" size="headline-small start-xl:headline-large">
            {{$t('review_section.title')}}
          </BaseText>
          <BaseText position="text-center" size="body-text-x-space">
            {{$t('review_section.description')}}
          </BaseText>
        </div>
      </div>
    </template>

    <div class="reviews-grid self-center gap-s40 mt-s20">
      <template
        v-for="(review, index) in reviews"
      >
        <ReviewCard
          v-bind="review"
          :key="index"
          :background="background"
        />
      </template>
    </div>

  </CentralizedSection>
</template>
<script>
import { BaseText, CentralizedSection } from '@/components/misc';
import ReviewCard from '@/modules/about/ReviewCard';

export default {
  name: 'ReviewSection',
  components: {
    BaseText,
    CentralizedSection,
    ReviewCard,
  },
  props: {
    background: {
      type: String,
      default: 'bg-background-page',
    },
    maxWidth: {
      type: String,
      default: '1032px',
    },
    reviews: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>
<style scoped>
.review-header {
  @apply gap-s16 flex flex-col items-center text-justify;
}

.reviews-grid {
  @apply grid grid-cols-1;
}

@screen start-lg {
  .reviews-grid {
    @apply grid-cols-3;
  }
}
</style>
