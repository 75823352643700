<template>
  <IntroVideoSection>
    <BaseText tag="h1" size="text__headline">
      {{ $t('about_us.top_section.title') }}
    </BaseText>
    <BaseText tag="h2" size="text__subheadline mt-s8">
      {{ $t('about_us.top_section.subtitle') }}
    </BaseText>
  </IntroVideoSection>
</template>

<script>
import { BaseText, IntroVideoSection } from '@/components/misc';

export default {
  name: 'DesktopVersion',
  components: {
    BaseText,
    IntroVideoSection,
  },
};
</script>

<style scoped>
  .text__headline {
    font-size: 40px;
    line-height: 55px;
    font-weight: 700;
  }
  .text__subheadline {
    font-size: 24px;
    line-height: 40px;
    font-weight: 400;
  }
</style>
