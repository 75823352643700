<template>
  <Card class="news-card-grid cursor-pointer gap-s40 p-s24" :background="background" @click.native="() => goToExternalPageGlobal(link)">
    <ImageResize
      type="about_us_press"
      slot="image"
      :src="image"
      :alt="title"
    />
    <div class="flex flex-col gap-s8">
      <div class="flex items-center gap-s12">
        <ImageResize
          :height="logo.height"
          :width="logo.width"
          :src="logo.src"
        />
        <BaseText
          size="body-text-x-space">{{ readableDate }}</BaseText>
      </div>
      <BaseText
        color="text-text-active-2"
        size="subheadline-medium">{{ title }}</BaseText>
      <BaseText
        class=""
        size="body-text-x-space">
        {{ description }}...
        <BaseText
          tag="span"
          color="text-text-active-2"
          size="body-text-x-large">
          {{$t('about_us.news_section.card_read_more')}}
        </BaseText>
      </BaseText>
    </div>
  </Card>
</template>

<script>
import { DateTime } from 'luxon';

import { BaseText, Card, ImageResize } from '@/components/misc';

export default {
  name: 'PressCard',
  components: {
    BaseText,
    Card,
    ImageResize
  },
  props: {
    date: {
      type: Number,
      required:true,
    },
    background: {
      type: String,
      default: 'bg-background-page',
    },
    description: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    logo: {
      type: Object,
      default: () => ({
        height: 30,
        width: 110,
        src: 'https://via.placeholder.com/110x30',
      })
    },
    link: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    }
  },
  computed: {
    readableDate(){
      return DateTime.fromMillis(this.date).setLocale('en-US').toLocaleString(DateTime.DATE_FULL);
    }
  }
};
</script>

<style scoped>
.news-card-grid {
  @apply grid;
}

.news-resume {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

@screen start-lg {
  .news-card-grid {
    grid-template-columns: auto 1fr;
  }
}
</style>
